<template>
  <div class="w-m-content" >
    <div class="w-m-header">
      <p class="font-14 font-bold no-margin" style="color: #293e52">
        Importação de lotes e bens via integração com software de terceiros
      </p>
      <div class="m-t-xs font-13 text-grey-7" style="color: #748396; padding-right: 20px; line-height: 1">
        Webservices, APIs, SOAP...
      </div>
    </div>
    <div class="w-m-body e-input-modern flex items-center center justify-center">
      <div class="text-center">
        <div class="text-center text-3x m-b"><u-icon color="grey" name="comment-alt-smile" type="fa"/></div>
        <div class="m-b-xs"><strong>Nenhum webservice configurado.</strong></div>
        Consulte seu gerente de contas SL para configurar as integrações com serviços de terceiros.
      </div>
    </div>
    <div class="w-m-footer text-right">
      <u-btn type="submit" label="Ok" color="grey" @click="$uloc.window.close($root.wid)" class="b-radius-3px" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImportacaoArquivo'
}
</script>

<style scoped>

</style>
